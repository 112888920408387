<template>
<b-modal
    ref="data-view-modal"
    size="xl"
    variant="primary"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Data View Configuration
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div class="modal-body">
            <div class="row">
                <div class="col-8">
                    <div class="form-group">
                        <label>Additional Columns</label>
                        <select
                            v-model="source"
                            class="form-control"
                            @change="resetForm"
                        >
                            <option value="">
                                Select a Type
                            </option>
                            <option
                                v-for="(dataType, idx) in dataTypes"
                                :key="`${dataType}_${idx}`"
                                :value="dataType"
                                :selected="source == dataType"
                            >
                                {{ dataType }}
                            </option>
                        </select>
                    </div>
                    <div v-if="canLimitBySemester" class="form-group">
                        <label>School Year</label>
                        <select
                            v-model="schoolYear"
                            :disabled="schoolTermId != null || month != null"
                            class="form-control"
                        >
                            <option :value="null" :selected="schoolYear == null">
                                Any School Year
                            </option>
                            <option
                                v-for="iterYear in schoolYears"
                                :key="iterYear"
                                :value="iterYear"
                                :selected="schoolYear == iterYear"
                            >
                                {{ iterYear }}
                            </option>
                        </select>
                    </div>
                    <div v-if="canLimitBySemester" class="form-group">
                        <label>School Term</label>
                        <select
                            v-model="schoolTermId"
                            :disabled="schoolYear != null || month != null"
                            class="form-control"
                        >
                            <option :value="null" :selected="schoolTermId == null">
                                Any School Term
                            </option>
                            <option
                                v-for="t in schoolTerms"
                                :key="`term_${t.schoolTermId}`"
                                :value="t.schoolTermId"
                                :selected="schoolTermId == t.schoolTermId"
                            >
                                {{ t.name }}
                            </option>
                        </select>
                    </div>
                    <div v-if="source == 'Report Cards' || source == 'Progress Averages'" class="form-group">
                        <label>Marking Period</label>
                        <select
                            v-model="schoolTermMarkingPeriodId"
                            class="form-control"
                        >
                            <option :value="null" :selected="schoolTermMarkingPeriodId == null" />
                            <option
                                v-for="mp in markingPeriods"
                                :key="`mp_${mp.schoolTermMarkingPeriodId}`"
                                :value="mp.schoolTermMarkingPeriodId"
                                :selected="schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId"
                            >
                                {{ mp.markingPeriodName }}
                            </option>
                        </select>
                    </div>
                    <div v-if="source == 'School Attendance'" class="form-group">
                        <label>Attendance Month</label>
                        <select
                            v-model="month"
                            :disabled="schoolYear != null || schoolTermId != null"
                            class="form-control"
                        >
                            <option :value="null" :selected="month == null">
                                Any Month
                            </option>
                            <option
                                v-for="m in months"
                                :key="`month_${m}`"
                                :value="m"
                                :selected="m == month"
                            >
                                {{ m }}
                            </option>
                        </select>
                    </div>

                    <div v-if="source == 'Anecdotals'" class="form-group">
                        <label>Anecdotal Category</label>
                        <select v-model="anecdotalEventCategoryId" class="form-control">
                            <option :value="null" :selected="anecdotalEventCategoryId == null">
                                All Categories
                            </option>
                            <option
                                v-for="anecdotalEventCategory in anecdotalEventCategories"
                                :key="`anec_${anecdotalEventCategory.anecdotalEventCategoryId}`"
                                :value="anecdotalEventCategory.anecdotalEventCategoryId"
                                :selected="anecdotalEventCategoryId == anecdotalEventCategory.anecdotalEventCategoryId"
                            >
                                {{ anecdotalEventCategory.anecdotalEventCategoryTitle }}
                            </option>
                        </select>
                    </div>
                    <div v-if="source" class="form-group">
                        <label>Plot:</label>
                        <div v-if="source == 'Anecdotals'" class="kt-checkbox-list">
                            <label
                                v-for="plot in anecdotalPlots"
                                :key="plot.title"
                                class="kt-checkbox"
                            >
                                <input v-model="plot.selected" type="checkbox">
                                {{ plot.title }}
                                <span />
                            </label>
                        </div>
                        <div v-if="source == 'Report Cards'" class="kt-checkbox-list">
                            <label
                                v-for="plot in reportCardPlots"
                                :key="plot.title"
                                class="kt-checkbox"
                            >
                                <input v-model="plot.selected" type="checkbox">
                                {{ plot.title }}
                                <span />
                            </label>
                        </div>
                        <div v-if="source == 'School Attendance'" class="kt-checkbox-list">
                            <label
                                v-for="plot in schoolAttendancePlots"
                                :key="plot.title"
                                class="kt-checkbox"
                            >
                                <input v-model="plot.selected" type="checkbox">
                                {{ plot.title }}
                                <span />
                            </label>
                        </div>
                        <div v-if="source == 'Progress Averages'" class="kt-checkbox-list">
                            <label
                                v-for="plot in progressPlots"
                                :key="plot.title"
                                class="kt-checkbox"
                            >
                                <input v-model="plot.selected" type="checkbox">
                                {{ plot.title }}
                                <span />
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <a class="btn btn-success" @click="addColumn">
                            Add Column(s)
                        </a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label>Enabled Columns</label>
                        <div class="form-text text-muted">
                            Drag and drop to reorder columns, or uncheck to remove.
                        </div>
                        <draggable
                            :list="columns"
                            class="kt-checkbox-list pl-3 pt-3"
                            :move="checkMove"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                            <label
                                v-for="(column, idx) in columns"
                                :key="`col_${column.name}_${idx}`"
                                class="kt-checkbox"
                            >
                                <input
                                    v-model="column.enabled"
                                    :disabled="column.required"
                                    type="checkbox"
                                >
                                {{ column.title }}
                                <span />
                            </label>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button class="btn btn-primary" @click="apply()">
            Apply Changes
        </button>
    </template>
</b-modal>
</template>

<script>
import Types from '../store/Types';
import moment from 'moment';
import { Column, getDefaultColumns } from '../store/state/studentDataView';
import studentMixins from '../store/mixins/studentMixins';
import draggable from "vuedraggable";

export default {
    name: 'StudentDataViewModal',
    components: {
        draggable,
    },
    mixins: [
        studentMixins,
    ],
    data() {
        return {
            columns: [],
            source: null,
            schoolYear: null,
            schoolTermId: null,
            month: null,
            dragging: false,
            anecdotalEventCategoryId: null,
            schoolTermMarkingPeriodId: null,
            anecdotalPlots: [
                { name: 'totalAnecdotalEvents', title: "Events", selected: true },
                { name: 'totalAnecdotalTotalResolved', title: "Resolved", selected: false },
                { name: 'totalAnecdotalPositivePoints', title: "Positive Points", selected: false },
                { name: 'totalAnecdotalNegativePoints', title: "Negative Points", selected: false },
                { name: 'totalAnecdotalResolvedPoints', title: "Resolved Points", selected: false },
            ],
            reportCardPlots: [
                { name: 'reportCardAverage', title: "Report Card Average", selected: true },
                { name: 'reportCardTotalCourses', title: "Total Courses", selected: false },
                { name: 'reportCardPassing', title: "Total Passing", selected: false },
                { name: 'reportCardFailing', title: "Total Failing", selected: false },
            ],
            schoolAttendancePlots: [
                { name: 'totalAbsence', title: "Total Absences", selected: true },
                { name: 'totalPresent', title: "Total Present", selected: false },
                { name: 'totalExcused', title: "Total Excused", selected: false },
                { name: 'totalLate', title: "Total Late", selected: false },
            ],
            progressPlots: [
                { name: 'progressAverage', title: "Progress Average", selected: true },
                { name: 'progressTotalCourses', title: "Total Courses", selected: false },
            ],
        };
    },
    computed: {
        dataTypes() {
            return [
                'Anecdotals',
                'Report Cards',
                'School Attendance',
                'Progress Averages',
            ]
        },
        canLimitBySemester() {
            const { source } = this;
            return [
                'Anecdotals',
                'School Attendance',
            ].includes(source);
        },
        months() {
            const { schoolTerms } = this;
            const months = [];
            schoolTerms.forEach(function (schoolTerm) {
                const { termEnd, termStart } = schoolTerm;
                const start = moment(termStart.substring(0, 10), 'YYYY-MM-DD');
                const end = moment(termEnd.substring(0, 10), 'YYYY-MM-DD');

                for (let m = start; m.isSameOrBefore(end); m.add(1, 'month')) {
                    const month = m.format('YYYY-MM');
                    if (!months.includes(month)) months.push(month);
                }
            });

            const output = months.sort().reverse();
            return output;
        },
        schoolTerms() {
            return this.$store.state.database.schoolTerms;
        },
        schoolYears() {
            const { schoolTerms } = this;
            return [...new Set(schoolTerms.map(term => term.schoolYear))];
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods;
        },
        anecdotalEventCategories() {
            return this.$store.state.database.anecdotalEventCategories;
        },
        sections() {
            return [...new Set(this.columns.map(column => column.source))];
        },
    },
    methods: {
        addColumn() {
            const { showError } = this;
            const { source, schoolTerms, columns } = this;
            if (source == 'Anecdotals') {
                const { anecdotalPlots, schoolYear, schoolTermId, anecdotalEventCategoryId } = this;
                const plots = anecdotalPlots.filter(plot => plot.selected);
                if (plots.length == 0) return showError('Please select at least one plot.');

                const schoolTerm = schoolTermId ? schoolTerms.find(term => term.schoolTermId == schoolTermId) : null;
                const anecdotalEventCategory = anecdotalEventCategoryId ? this.anecdotalEventCategories.find(category => category.anecdotalEventCategoryId == anecdotalEventCategoryId) : null;
                const schoolTermIds = schoolTermId ? [schoolTermId] : [];

                if (schoolYear) {
                    schoolTerms.forEach(function (t) {
                        if (t.schoolYear == schoolYear) {
                            schoolTermIds.push(t.schoolTermId);
                        }
                    });
                }

                plots.forEach(function(plot) {
                    let title = plot.title;
                    const name = plot.name;

                    const params = {
                        schoolTermIds,
                        anecdotalEventCategoryId,
                    };

                    if (schoolYear) {
                        if (anecdotalEventCategory) {
                            const { anecdotalEventCategoryTitle } = anecdotalEventCategory;
                            title = `${schoolYear} ${anecdotalEventCategoryTitle} ${title}`;
                        } else {
                            title = `${schoolYear} ${title}`;
                        }
                    } else if (schoolTermId) {
                        if (anecdotalEventCategory) {
                            const { anecdotalEventCategoryTitle } = anecdotalEventCategory;
                            title = `${schoolTerm.name} ${anecdotalEventCategoryTitle} ${title}`;
                        } else {
                            title = `${schoolTerm.name} ${title}`;
                        }
                    } else {
                        if (anecdotalEventCategory) {
                            const { anecdotalEventCategoryTitle } = anecdotalEventCategory;
                            title = `${anecdotalEventCategoryTitle} ${title}`;
                        } else {
                            title = `Total ${title}`;
                        }
                    }

                    const column = new Column({
                        name,
                        title,
                        params,
                        enabled: true,
                        dataType: 'number',
                        align: 'center',
                        source,
                    });

                    //insert after col 1
                    columns.splice(1, 0, column);

                });

            }

            if (source == 'Report Cards') {
                const { reportCardPlots, markingPeriods, schoolTermMarkingPeriodId } = this;
                if (!schoolTermMarkingPeriodId) return showError('Please select a marking period.');
                const plots = reportCardPlots.filter(plot => plot.selected);
                if (plots.length == 0) return showError('Please select at least one plot.');

                const markingPeriod = schoolTermMarkingPeriodId ? markingPeriods.find(mp => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) : null;

                plots.forEach(function(plot) {
                    let title = plot.title;
                    const name = plot.name;

                    const params = {
                        schoolTermMarkingPeriodId
                    };

                    title = `MP ${markingPeriod.markingPeriod} ${title}`;

                    const column = new Column({
                        name,
                        title,
                        params,
                        enabled: true,
                        dataType: 'number',
                        align: 'center',
                        source,
                    });

                    //insert after col 1
                    columns.splice(1, 0, column);

                });
            }

            if (source == 'School Attendance') {
                const { schoolAttendancePlots, schoolYear, schoolTermId, month } = this;
                const plots = schoolAttendancePlots.filter(plot => plot.selected);
                if (plots.length == 0) return showError('Please select at least one plot.');

                const schoolTerm = schoolTermId ? schoolTerms.find(term => term.schoolTermId == schoolTermId) : null;
                const schoolTermIds = schoolTermId ? [schoolTermId] : [];
                if (!schoolYear && !schoolTermId && !month) {
                    return showError('Please select a school year, term, or month.');
                }

                const params = {
                    schoolTermIds,
                    attendanceMonth: month,
                };

                plots.forEach(function(plot) {
                    let title = plot.title;
                    const name = plot.name;

                    if (schoolYear) {
                        title = `${schoolYear} ${title}`;
                    } else if (schoolTermId) {
                        title = `${schoolTerm.name} ${title}`;
                    } else if (month) {
                        title = `${month} ${title}`;
                    }

                    const column = new Column({
                        name,
                        title,
                        params,
                        enabled: true,
                        dataType: 'number',
                        align: 'center',
                        source,
                    });

                    //insert after col 1
                    columns.splice(1, 0, column);
                });
            }

            if (source == 'Progress Averages') {
                const { progressPlots, markingPeriods, schoolTermMarkingPeriodId } = this;
                if (!schoolTermMarkingPeriodId) return showError('Please select a marking period.');
                const plots = progressPlots.filter(plot => plot.selected);
                if (plots.length == 0) return showError('Please select at least one plot.');

                const markingPeriod = schoolTermMarkingPeriodId ? markingPeriods.find(mp => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) : null;

                plots.forEach(function(plot) {
                    let title = plot.title;
                    const name = plot.name;

                    const params = {
                        schoolTermMarkingPeriodId
                    };

                    title = `MP ${markingPeriod.markingPeriod} ${title}`;

                    const column = new Column({
                        name,
                        title,
                        params,
                        enabled: true,
                        dataType: 'number',
                        align: 'center',
                        source,
                    });

                    //insert after col 1
                    columns.splice(1, 0, column);

                });
            }

        },
        checkMove: function (e) {
            const { index, futureIndex } = e.draggedContext;
            if (futureIndex == 0 || index == 0) {
                return false;
            }
        },
        open() {
            const { state } = this.$store;
            const defaultColumns = getDefaultColumns();
            const currentColumns = [...state.database.studentDataView.columns];
            defaultColumns.forEach(column => {
                const exists = currentColumns.find((c) => c.name === column.name);
                // column.enabled = Boolean(exists);
                if (exists) return;
                column.enabled = false;
                currentColumns.push(column);
            });
            this.columns = [...currentColumns];
            // @ts-ignore
            this.$refs['data-view-modal'].show();
        },
        apply() {
            const filtered = this.columns.filter(column => column.enabled);
            // this.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_COLUMNS, filtered);
            const students = this.$_studentMixins_getStudents({});
            if (students.length == 0) return;
            this.$store.dispatch(Types.actions.POPULATE_STUDENT_DATA_VIEW, {
                students,
                columns: filtered,
                columnSortIndex: 0,
                callback: function () {
                    // v.key += 1;
                },
            });

            // @ts-ignore
            this.$refs['data-view-modal'].hide();
        },
        resetForm() {
            this.schoolTermId = null;
            this.schoolYear = null;
            this.month = null;
            this.anecdotalEventCategoryId = null;
        },
    },
};
</script>

<style scoped>
.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
