<template>
<div :key="`key_${title}`">
    <GeneralSubHeader>
        <template #left-buttons>
            <a
                v-if="view == 'table'"
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Show Table Settings"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="openConfig"
            >
                <i class="flaticon-cogwheel-2" />
            </a>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Export To CSV"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>

            <b-pagination
                :key="`key_${tableKey}`"
                v-model="currentPage"
                class="ml-2 mt-1 mb-0 student-pagnation"
                size="md"
                hide-goto-end-buttons
                :total-rows="totalRows"
                :limit="deviceType == 'mobile' ? 1 : 3"
                :per-page="perPage"
            />

            <div class="kt-input-icon kt-input-icon--right kt-subheader__search pl-3">
                <input
                    v-model="filter"
                    type="text"
                    class="form-control"
                    placeholder="Filter students..."
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                    <span>
                        <i class="flaticon2-search-1" />
                    </span>
                </span>
            </div>
        </template>
        <template #buttons>
            <span class="text-muted mr-2">View:</span>
            <a
                href="#"
                :class="['btn kt-subheader__btn-secondary', view == 'table' ? 'active' : '']"
                @click.stop.prevent="openView('table')"
            >
                Table
            </a>
            <a
                href="#"
                :class="['btn kt-subheader__btn-secondary', view == 'list' ? 'active' : '']"
                @click.stop.prevent="openView('list')"
            >
                List
            </a>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-student-dataview kt-container--fluid kt-grid__item kt-grid__item--fluid pl-2 ml-3 mr-4">
        <div v-if="totalRows == 0" class="col">
            <div
                class="alert alert-light alert-elevate fade show"
                role="alert"
            >
                <div class="alert-icon">
                    <i class="la la-info-circle kt-font-danger" />
                </div>
                <div class="alert-text">
                    Loading data, please wait...
                </div>
            </div>
        </div>

        <template v-else>
            <template v-if="view == 'table'">
                <div class="kt-portlet p-0 m-0">
                    <div class="kt-portlet__body p-0 m-0">
                        <StudentDataViewHotTable
                            :key="tableKey"
                            ref="hotContainer"
                        />
                    </div>
                </div>
            </template>
            <StudentList v-else />
        </template>

        <StudentDataViewModal ref="table-config" />
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import Types from '../store/Types';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import StudentDataViewModal from '../components/StudentDataViewModal.vue';
import StudentList from '../components/StudentList.vue';
import StudentDataViewHotTable from '../components/StudentDataViewHotTable.vue';
import globalMixins from '../store/mixins/globalMixins';
import studentMixins from '../store/mixins/studentMixins';
// import { getStudentBadge } from '../store/mixins/badgeMixins';
import { getColumns } from '../store/state/studentDataView';
import { createDataSet } from '../store/actions/studentDataView';
import moment from 'moment';

let populating = false;
export default Vue.extend({
    name: 'StudentDataView',
    components: {
        GeneralSubHeader,
        StudentList,
        StudentDataViewHotTable,
        StudentDataViewModal,
    },
    mixins: [
        studentMixins,
        globalMixins
    ],
    data() {
        return {
            tableKey: 0,
            hotRef: null,
            debounce: null,
            filter: '',
        };
    },
    computed: {
        windowSize() {
            return `${this.$store.state.window.width}_${this.$store.state.window.height}`;
        },
        user() {
            return this.$store.state.user;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
        title() {
            return this.$route.name;
        },
        sortIndex() {
            return this.$store.state.database.studentDataView.sortIndex;
        },
        totalRows() {
            return this.$store.state.database.studentDataView.totalRows;
        },
        perPage() {
            return this.$store.state.database.studentDataView.perPage;
        },
        currentPage: {
            get() {
                return this.$store.state.database.studentDataView.currentPage;
            },
            set(currentPage) {
                this.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_CURRENT_PAGE, currentPage);
            }
        },
        columns() {
            return this.$store.state.database.studentDataView.columns;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        view() {
            return this.$route.query.display || 'list';
        }
    },
    watch: {
        columns() {
            // console.log('columns changed');
            this.tableKey += 1;
        },
        currentPage() {
            // console.log('currentPage changed');
            this.debouncePopulate();
        },
        perPage() {
            // console.log('perPage changed');
            this.debouncePopulate();
        },
        windowSize() {
            // console.log('windowSize changed');
            // redraw the table when the window size changes
            this.tableKey += 1;
        },
        databaseIsReady() {
            // console.log('databaseIsReady changed');
            this.debouncePopulate();
        },
        filter() {
            // console.log('filter changed');
            this.debouncePopulate();
        },
    },
    mounted() {
        this.filter = this.$store.state.database.studentDataView.filter;
        this.debouncePopulate();
        const v = this;
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            setTimeout(() => {
                v.tableKey += 1;
            }, 1000);
        });
    },
    methods: {
        exportData() {
            // need a new action to pull non paginated data here
            const students = this.$_studentMixins_getStudents({});
            const { columns, sortIndex, encryptionEnabled } = this;
            const rows = createDataSet(this.$store.state, students, columns, sortIndex);

            const csv = rows
                .map(function(columns) {
                    return columns.map(function(column, idx) {
                        const { cellValue } = column;
                        if (idx === 0) {
                            return !encryptionEnabled ?
                                `"${cellValue.lastName}, ${cellValue.firstName}"` :
                                `"${cellValue.maskedStudentName}"`;
                        }
                        return `"${cellValue}"`;
                    }).join(',');
                });

            // add the header row
            const headerRow = columns.map((c) => {
                return `"${c.title}"`;
            }).join(',');
            csv.unshift(headerRow);

            const date = moment(new Date()).format('YYYYMMDD');
            const filename = `studentExport_${date}`;
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv.join('\n'))}`;
            anchor.target = '_blank';
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
        openConfig() {
            this.$refs['table-config'].open();
        },
        openView(display) {
            const { $router, $route } = this;
            const { name, params } = $route;
            $router.push({
                name, params,
                query: { display }
            });
        },
        debouncePopulate() {
            const { populate, filter, $store } = this;
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            this.debounce = setTimeout(() => {
                $store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_FILTER, filter);
                populate();
            }, 1000);
        },
        populate() {
            if (!this.databaseIsReady) return;
            if (populating) return;
            const students = this.$_studentMixins_getStudents({});
            if (students.length == 0) return;

            populating = true;
            const v = this;
            const {state} = this.$store;
            console.log("populate called");
            this.$store.dispatch(Types.actions.POPULATE_STUDENT_DATA_VIEW, {
                students,
                columns: this.columns.length == 0 ? getColumns(state) : this.columns,
                columnSortIndex: 0,
                callback: function() {
                    console.log("populate callback");
                    populating = false;
                    v.tableKey += 1;
                },
            });
        },
    }
});

</script>

<style lang="scss">

.student-pagnation > li > button, .student-pagnation .page-item.disabled .page-link {
    background: #f2f3f7;
    color: #959cb6;
    font-weight: 500;
}

div.kt-student-dataview {
    overflow-x: hidden;
}

</style>
