var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hotSettings.data.length > 0
    ? _c(
        "div",
        { staticClass: "sd-hot-table-container" },
        [
          _c(
            "HotTable",
            {
              key: `table_${_vm.key}`,
              ref: "sdHotTable",
              attrs: { settings: _vm.hotSettings },
            },
            [
              _c(
                "hot-column",
                { attrs: { width: 180, "read-only": true } },
                [_c("StudentHotColumn", { attrs: { "hot-renderer": "" } })],
                1
              ),
              _vm._l(_vm.columnsShifted, function (column, idx) {
                return _c("hot-column", {
                  key: `${column.name}_${idx}`,
                  attrs: { width: column.width, renderer: _vm.cellRenderer },
                })
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }