var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "data-view-modal",
    attrs: { size: "xl", variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Data View Configuration "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Additional Columns")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.source,
                            expression: "source",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.source = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.resetForm,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(" Select a Type "),
                        ]),
                        _vm._l(_vm.dataTypes, function (dataType, idx) {
                          return _c(
                            "option",
                            {
                              key: `${dataType}_${idx}`,
                              domProps: {
                                value: dataType,
                                selected: _vm.source == dataType,
                              },
                            },
                            [_vm._v(" " + _vm._s(dataType) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm.canLimitBySemester
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("School Year")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.schoolYear,
                                expression: "schoolYear",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled:
                                _vm.schoolTermId != null || _vm.month != null,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.schoolYear = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                domProps: {
                                  value: null,
                                  selected: _vm.schoolYear == null,
                                },
                              },
                              [_vm._v(" Any School Year ")]
                            ),
                            _vm._l(_vm.schoolYears, function (iterYear) {
                              return _c(
                                "option",
                                {
                                  key: iterYear,
                                  domProps: {
                                    value: iterYear,
                                    selected: _vm.schoolYear == iterYear,
                                  },
                                },
                                [_vm._v(" " + _vm._s(iterYear) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.canLimitBySemester
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("School Term")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.schoolTermId,
                                expression: "schoolTermId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled:
                                _vm.schoolYear != null || _vm.month != null,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.schoolTermId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                domProps: {
                                  value: null,
                                  selected: _vm.schoolTermId == null,
                                },
                              },
                              [_vm._v(" Any School Term ")]
                            ),
                            _vm._l(_vm.schoolTerms, function (t) {
                              return _c(
                                "option",
                                {
                                  key: `term_${t.schoolTermId}`,
                                  domProps: {
                                    value: t.schoolTermId,
                                    selected:
                                      _vm.schoolTermId == t.schoolTermId,
                                  },
                                },
                                [_vm._v(" " + _vm._s(t.name) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.source == "Report Cards" ||
                  _vm.source == "Progress Averages"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Marking Period")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.schoolTermMarkingPeriodId,
                                expression: "schoolTermMarkingPeriodId",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.schoolTermMarkingPeriodId = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", {
                              domProps: {
                                value: null,
                                selected: _vm.schoolTermMarkingPeriodId == null,
                              },
                            }),
                            _vm._l(_vm.markingPeriods, function (mp) {
                              return _c(
                                "option",
                                {
                                  key: `mp_${mp.schoolTermMarkingPeriodId}`,
                                  domProps: {
                                    value: mp.schoolTermMarkingPeriodId,
                                    selected:
                                      _vm.schoolTermMarkingPeriodId ==
                                      mp.schoolTermMarkingPeriodId,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(mp.markingPeriodName) + " "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.source == "School Attendance"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Attendance Month")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.month,
                                expression: "month",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled:
                                _vm.schoolYear != null ||
                                _vm.schoolTermId != null,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.month = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                domProps: {
                                  value: null,
                                  selected: _vm.month == null,
                                },
                              },
                              [_vm._v(" Any Month ")]
                            ),
                            _vm._l(_vm.months, function (m) {
                              return _c(
                                "option",
                                {
                                  key: `month_${m}`,
                                  domProps: {
                                    value: m,
                                    selected: m == _vm.month,
                                  },
                                },
                                [_vm._v(" " + _vm._s(m) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.source == "Anecdotals"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Anecdotal Category")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.anecdotalEventCategoryId,
                                expression: "anecdotalEventCategoryId",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.anecdotalEventCategoryId = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                domProps: {
                                  value: null,
                                  selected:
                                    _vm.anecdotalEventCategoryId == null,
                                },
                              },
                              [_vm._v(" All Categories ")]
                            ),
                            _vm._l(
                              _vm.anecdotalEventCategories,
                              function (anecdotalEventCategory) {
                                return _c(
                                  "option",
                                  {
                                    key: `anec_${anecdotalEventCategory.anecdotalEventCategoryId}`,
                                    domProps: {
                                      value:
                                        anecdotalEventCategory.anecdotalEventCategoryId,
                                      selected:
                                        _vm.anecdotalEventCategoryId ==
                                        anecdotalEventCategory.anecdotalEventCategoryId,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          anecdotalEventCategory.anecdotalEventCategoryTitle
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.source
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Plot:")]),
                        _vm.source == "Anecdotals"
                          ? _c(
                              "div",
                              { staticClass: "kt-checkbox-list" },
                              _vm._l(_vm.anecdotalPlots, function (plot) {
                                return _c(
                                  "label",
                                  {
                                    key: plot.title,
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: plot.selected,
                                          expression: "plot.selected",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(plot.selected)
                                          ? _vm._i(plot.selected, null) > -1
                                          : plot.selected,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = plot.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(plot, "selected", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(plot.title) + " "),
                                    _c("span"),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.source == "Report Cards"
                          ? _c(
                              "div",
                              { staticClass: "kt-checkbox-list" },
                              _vm._l(_vm.reportCardPlots, function (plot) {
                                return _c(
                                  "label",
                                  {
                                    key: plot.title,
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: plot.selected,
                                          expression: "plot.selected",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(plot.selected)
                                          ? _vm._i(plot.selected, null) > -1
                                          : plot.selected,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = plot.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(plot, "selected", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(plot.title) + " "),
                                    _c("span"),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm.source == "School Attendance"
                          ? _c(
                              "div",
                              { staticClass: "kt-checkbox-list" },
                              _vm._l(
                                _vm.schoolAttendancePlots,
                                function (plot) {
                                  return _c(
                                    "label",
                                    {
                                      key: plot.title,
                                      staticClass: "kt-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: plot.selected,
                                            expression: "plot.selected",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(plot.selected)
                                            ? _vm._i(plot.selected, null) > -1
                                            : plot.selected,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = plot.selected,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    plot,
                                                    "selected",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    plot,
                                                    "selected",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(plot, "selected", $$c)
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" " + _vm._s(plot.title) + " "),
                                      _c("span"),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.source == "Progress Averages"
                          ? _c(
                              "div",
                              { staticClass: "kt-checkbox-list" },
                              _vm._l(_vm.progressPlots, function (plot) {
                                return _c(
                                  "label",
                                  {
                                    key: plot.title,
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: plot.selected,
                                          expression: "plot.selected",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(plot.selected)
                                          ? _vm._i(plot.selected, null) > -1
                                          : plot.selected,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = plot.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  plot,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(plot, "selected", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(plot.title) + " "),
                                    _c("span"),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-success",
                        on: { click: _vm.addColumn },
                      },
                      [_vm._v(" Add Column(s) ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Enabled Columns")]),
                      _c("div", { staticClass: "form-text text-muted" }, [
                        _vm._v(
                          " Drag and drop to reorder columns, or uncheck to remove. "
                        ),
                      ]),
                      _c(
                        "draggable",
                        {
                          staticClass: "kt-checkbox-list pl-3 pt-3",
                          attrs: { list: _vm.columns, move: _vm.checkMove },
                          on: {
                            start: function ($event) {
                              _vm.dragging = true
                            },
                            end: function ($event) {
                              _vm.dragging = false
                            },
                          },
                        },
                        _vm._l(_vm.columns, function (column, idx) {
                          return _c(
                            "label",
                            {
                              key: `col_${column.name}_${idx}`,
                              staticClass: "kt-checkbox",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: column.enabled,
                                    expression: "column.enabled",
                                  },
                                ],
                                attrs: {
                                  disabled: column.required,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(column.enabled)
                                    ? _vm._i(column.enabled, null) > -1
                                    : column.enabled,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = column.enabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            column,
                                            "enabled",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            column,
                                            "enabled",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(column, "enabled", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" " + _vm._s(column.title) + " "),
                              _c("span"),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function ($event) {
                    return _vm.apply()
                  },
                },
              },
              [_vm._v(" Apply Changes ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }