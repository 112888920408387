<template>
<div
    v-if="hotSettings.data.length > 0"
    class="sd-hot-table-container"
>
    <HotTable
        ref="sdHotTable"
        :key="`table_${key}`"
        :settings="hotSettings"
    >
        <hot-column
            :width="180"
            :read-only="true"
        >
            <StudentHotColumn hot-renderer />
        </hot-column>

        <!-- this works -->
        <hot-column
            v-for="(column, idx) in columnsShifted"
            :key="`${column.name}_${idx}`"
            :width="column.width"
            :renderer="cellRenderer"
        />

        <!-- this way does not repaint the table! -->
        <!-- <HotColumn
            v-for="column in columns"
            :key="`${column.name}_${key}`"
            :width="column.width"
        >
            <component
                :is="column.componentName"
                hot-renderer
            />
            <TextHotColumn hot-renderer />
        </HotColumn> -->
    </HotTable>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import Types from '../store/Types';
import hotTableMixins from '../store/mixins/hotTableMixins';
import studentMixins from '../store/mixins/studentMixins';
import panelMixins from '../store/mixins/panelMixins';
import { hotDefaults } from '../store/hotDefaults';

import StudentHotColumn from './StudentDataView/StudentHotColumn.vue';
// import TextHotColumn from './StudentDataView/TextHotColumn.vue';
// import EmailHotColumn from './StudentDataView/EmailHotColumn.vue';
// import HotCellAchievementBadge from './StudentDataView/HotCellAchievementBadge.vue';


export default Vue.extend({
    name: 'StudentDataViewHotTable',
    components: {
        // HotCellAchievementBadge,
        StudentHotColumn,
        // TextHotColumn,
        // EmailHotColumn,
    },
    mixins: [
        hotTableMixins,
        studentMixins,
        panelMixins,
    ],
    data() {
        const data = {
            key: 1,
            debounced: null,
            hotSettings: {
                data: [],
                autoColumnSize: false,
                autoRowSize: false,
                comments: false,
                disableVisualSelection: true,
                // fragmentSelection: true,
                editor: false,
                readOnly: true,
                fillHandle: {
                    autoInsertRow: false,
                    autoInsertCol: false,
                },
                fixedColumnsStart: 1,
                fixedRowsTop: 1,

                indicator: true,
                rowHeights: 70,
                colHeaders: false,
                rowHeaders: false,
            },
        };

        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        sortIndex() {
            return this.$store.state.database.studentDataView.sortIndex;
        },
        sortOrder: {
            get() {
                return this.$store.state.database.studentDataView.sortOrder;
            },
            set(value) {
                this.$store.commit(Types.mutations.TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER);
            },
        },
        filter() {
            return this.$store.state.database.studentDataView.filter;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        user() {
            return this.$store.state.user;
        },
        columns() {
            return [...this.$store.state.database.studentDataView.columns];
        },
        columnsShifted() {
            const columns = [...this.$store.state.database.studentDataView.columns];
            columns.shift();
            return columns;
        },
        currentPage() {
            return this.$store.state.database.studentDataView.currentPage;
        },
        perPage() {
            return this.$store.state.database.studentDataView.perPage;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
    },
    watch: {
        sortOrder() {
            this.debouncePopulate();
        },
        currentPage() {
            this.debouncePopulate();
        },
        // filter() {
        //     this.debouncePopulate();
        // },
    },
    mounted() {
        this.populate();
    },
    methods: {
        cellRenderer(instance, td, row, col, prop) {
            const cell = this.hotSettings.data[row][col];
            const { cellValue, column } = cell;
            const { sortIndex, sortOrder } = this.$store.state.database.studentDataView;
            const element = td;
            if (row === 0) { // Header
                const div = document.createElement('div');
                div.classList.add('table-header');
                div.classList.add('sort');

                if (sortIndex == col) {
                    div.classList.add(`sort-${sortOrder}`);
                }
                div.innerHTML = column.title;
                div.setAttribute('title', column.title);
                element.innerHTML = '';
                element.appendChild(div);
                return;
            }

            element.style.paddingLeft = '10px';
            element.style.paddingRight = '10px';
            element.style.textAlign = column.align;
            element.innerHTML = `${cellValue}`;

        },
        debouncePopulate() {
            if (this.debounced) {
                clearTimeout(this.debounced);
            }
            this.debounced = setTimeout(() => {
                this.populate();
            }, 750);
        },
        populate() {
            if (this.deviceType == 'tablet' || this.deviceType == 'mobile') {
                this.hotSettings.height = document.documentElement.clientHeight - 130;
            } else {
                this.hotSettings.height = document.documentElement.clientHeight - 160;
            }
            if (this.columns.length === 0) return;

            const rows = [...this.$store.state.database.studentDataView.rows]
            this.hotSettings.data = rows;

            //sorting
            const v = this;
            this.hotSettings.afterOnCellMouseDown = function (event, coords) {
                const {row, col} = coords;
                if (row !== 0) return;
                const students = v.$_studentMixins_getStudents({});
                v.$store.commit(Types.mutations.TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER);
                v.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_SORT_INDEX, col);
                v.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_CURRENT_PAGE, 1);
                v.$store.dispatch(Types.actions.POPULATE_STUDENT_DATA_VIEW, {
                    students,
                    columns: v.columns,
                    columnSortIndex: col,
                    callback: function() {
                        v.key += 1;
                    },
                });
            }
        },
    },
});

</script>

<style scoped src="../css/colors.scss" lang="scss" />

<style lang="scss">

.sd-hot-table-container {
    width: 100%;
    overflow-x: hidden;
}

.sd-hot-table-container .handsontable td, .sd-hot-table-container .handsontable tr, .sd-hot-table-container .handsontable th {
    border-top: 0 !important;
    /* border-bottom: 0 !important; */
    border-bottom-color:  #ebedf2;
    border-left: 0 !important;
    border-right: 0 !important;
    margin-top: 4px;
    margin-bottom: 4px;
}

.sd-hot-table-container .table-header {
    text-transform: uppercase;
    border-bottom-color: inherit;
    width: auto;
    color: #B5B5C3 !important;
    max-height: 64px;
    white-space: break-spaces;
    text-align: center;
}
.sd-hot-table-container .sort:hover {
    cursor: pointer;
    color: #007aff !important;
}

.sd-hot-table-container td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
}

.sd-hot-table-container td > div.table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space-collapse: collapse;
    width: 100%;
    display: grid;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.3rem;
}

// .sd-hot-table-container .table-header.sort:before {
//     display: inline-block;
//     font: normal normal normal 10px/1 "LineAwesome";
//     letter-spacing: -4px;
//     margin-left: 4px;
//     margin-right: 4px;
// }

// .sd-hot-table-container .table-header.sort.sort-asc:before {
//     content: "\f11f"; /* la-arrow-down */
//     color: #007bff;
// }

// .sd-hot-table-container .table-header.sort.sort-desc:before {
//     content: "\f122"; /* la-arrow-up */
//     color: #007bff;
// }

.sd-hot-table-container .table-header.sort.sort-asc,
.sd-hot-table-container .table-header.sort.sort-desc {
    color: #007aff !important;
}

</style>
