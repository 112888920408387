import { round, decimalToPercent } from '../mixins/utilMixins';

const columns = [];

columns.push(

    new Column({
        name: 'student',
        title: 'Student',
        enabled: true,
        required: true,
        width: 200,
        dataType: 'text',
        source: 'Biographical',

    }),
    new Column({
        name: 'extStudentId',
        title: 'Student Id',
        enabled: true,
        containsPII: true,
        source: 'Biographical',
        align: 'center',
        width: 110,
    }),
    new Column({
        name: 'schoolEmail',
        title: 'Email',
        width: 100,
        containsPII: true,
        dataType: 'email',
        source: 'Biographical',
    }),
    new Column({
        name: 'googleEmail',
        title: 'Google',
        width: 100,
        containsPII: true,
        dataType: 'email',
        source: 'Biographical',
    }),
    new Column({
        name: 'primaryGuardian',
        title: 'Guardian',
        enabled: true,
        dataType: 'text',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'address',
        title: 'Address',
        enabled: true,
        width: 200,
        dataType: 'text',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'phoneNumber',
        title: 'Phone Number',
        enabled: true,
        dataType: 'text',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'gender',
        title: 'Gender',
        width: 40,
        dataType: 'text',
        align: 'center',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'dob',
        title: 'Dob',
        containsPII: true,
        dataType: 'text',
        align: 'center',
        source: 'Biographical',
    }),
    new Column({
        name: 'gradeLevel',
        title: 'Grade Level',
        align: 'center',
        dataType: 'text',
        width: 60,
        enabled: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'homeRoom',
        title: 'Home Room',
        align: 'center',
        width: 60,
        dataType: 'text',
        enabled: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'specialEd',
        title: 'Special Ed',
        dataType: 'boolean',
        align: 'center',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'cohortYear',
        title: 'Cohort',
        dataType: 'text',
        align: 'center',
        containsPII: true,
        source: 'Biographical',
    }),
    new Column({
        name: 'englishLearner',
        title: 'ELL',
        dataType: 'boolean',
        align: 'center',
        containsPII: true,
        source: 'Biographical',
    }),
);

function getColumns(state) {

    const { schoolTerms, markingPeriods } = state.database;
    const defaultColumns = [...columns];
/*
    defaultColumns.push(new Column({
        name: `totalAnecdotalEvents`,
        title: `Anecdotal Events`,
        enabled: true,
        dataType: 'number',
        align: 'center',
        source: 'Anecdotals',
        format(studentAggregates) {
            const { anecdotals } = studentAggregates;
            return anecdotals.reduce(function(n, item) {
                return n + item.totalEvents;
            }, 0);
        }
    }));

    defaultColumns.push(new Column({
        name: `totalAnecdotalPositivePoints`,
        title: `Anecdotal Points`,
        enabled: true,
        dataType: 'number',
        align: 'center',
        source: 'Anecdotals',
        format(studentAggregates) {
            const { anecdotals } = studentAggregates;
            return anecdotals.reduce(function(n, item) {
                return n + item.totalPositivePoints;
            }, 0);
        }
    }));

    defaultColumns.push(new Column({
        name: `totalAnecdotalNegativePoints`,
        title: `Anecdotal Negative Points`,
        enabled: true,
        dataType: 'number',
        align: 'center',
        source: 'Anecdotals',
        format(studentAggregates) {
            const { anecdotals } = studentAggregates;
            return anecdotals.reduce(function(n, item) {
                return n + item.totalNegativePoints;
            }, 0);
        }
    }));

    schoolTerms.forEach(function (schoolTerm) {
        const { name, schoolTermId } = schoolTerm;

        defaultColumns.push(new Column({
            name: `totalAnecdotalEvents_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Anecdotal Events`,
            enabled: true,
            dataType: 'number',
            align: 'center',
            source: 'Anecdotals',
            format(studentAggregates) {
                const { anecdotals } = studentAggregates;
                return anecdotals.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalEvents;
                }, 0);
            }
        }));

        defaultColumns.push(new Column({
            name: `totalAnecdotalPoints_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Anecdotal Points`,
            enabled: false,
            dataType: 'number',
            align: 'center',
            source: 'Anecdotals',
            format(studentAggregates) {
                const { anecdotals } = studentAggregates;
                return anecdotals.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalPositivePoints;
                }, 0);
            }
        }));

        defaultColumns.push(new Column({
            name: `totalAnecdotalNegPoints_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Anecdotal Negative Points`,
            enabled: true,
            dataType: 'number',
            align: 'center',
            source: 'Anecdotals',
            format(studentAggregates) {
                const { anecdotals } = studentAggregates;
                return anecdotals.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalNegativePoints;
                }, 0);
            }
        }));


        defaultColumns.push(new Column({
            name: `totalPresent_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Present`,
            enabled: false,
            dataType: 'number',
            align: 'center',
            source: 'School Attendance',
            format(studentAggregates) {
                const { studentAttendance } = studentAggregates;
                return studentAttendance.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalPresent;
                }, 0);
            }
        }));

        defaultColumns.push(new Column({
            name: `totalAbsent_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Absent`,
            enabled: true,
            dataType: 'number',
            align: 'center',
            source: 'School Attendance',
            format(studentAggregates) {
                const { studentAttendance } = studentAggregates;
                return studentAttendance.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalAbsence;
                }, 0);
            }
        }));

        defaultColumns.push(new Column({
            name: `totalExcused_${schoolTermId}`,
            params: { schoolTermId },
            title: `${name} - Excused`,
            enabled: true,
            dataType: 'number',
            align: 'center',
            source: 'School Attendance',
            format(studentAggregates) {
                const { studentAttendance } = studentAggregates;
                return studentAttendance.reduce(function(n, item) {
                    if (item.schoolTermId !== schoolTermId) return n;
                    return n + item.totalExcused;
                }, 0);
            }
        }));
    });
*/

    markingPeriods.forEach(function (mp) {
        const { schoolTermMarkingPeriodId, markingPeriod, schoolTermId } = mp;

        /* defaultColumns.push(new Column({
            name: `progressAverage_${schoolTermMarkingPeriodId}`,
            params: { schoolTermMarkingPeriodId },
            title: `MP${markingPeriod} Progress Average`,
            enabled: true,
            dataType: 'percentage',
            align: 'center',
            source: 'Progress Averages',
            format(studentAggregates) {
                const { progressAverages } = studentAggregates;
                const progressAverage = progressAverages.find((row) => row.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId);
                if (!progressAverage) return '';
                const average = `${decimalToPercent(progressAverage.progressAverage)}`;
                return average;
            }
        })); */

        defaultColumns.push(new Column({
            name: `reportCardAverage`,
            params: { schoolTermMarkingPeriodId },
            title: `MP${markingPeriod} Report Card Average`,
            dataType: 'percentage',
            align: 'center',
            enabled: true,
            source: 'Report Cards',
        }));
    });

    const { clientSideEncryptionEnabled } = state.user.school;
    const output = defaultColumns.filter(function (c) {
        if (!c.enabled) return false;
        if (clientSideEncryptionEnabled && c.containsPII) {
            return false;
        }
        return true;
    });
    console.log('output', output);
    return output;
}

function getDefaultColumns() {
    return columns;
}

function Column(props) {
    const { name } = props;
    this.name = props.name;
    this.title = props.title;
    this.source = props.source;


    this.dataType = props.dataType || "number";
    this.align = props.align || 'left';
    this.params = props.params || null;
    this.enabled = props.enabled || false;
    this.required = props.required || false;
    this.percentage = props.percentage || false;
    this.width = props.width || 100;
    this.containsPII = props.containsPII || false;

    // const format = function (obj) {
    //     return obj[name];
    // };

    // this.format = props.format || format;

}

export default getColumns;

export { Column, getColumns, getDefaultColumns };
