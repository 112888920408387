var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "left-buttons",
            fn: function () {
              return [
                _vm.view == "table"
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottomleft",
                            modifiers: { hover: true, bottomleft: true },
                          },
                        ],
                        staticClass: "btn kt-subheader__btn-primary btn-icon",
                        attrs: { href: "#", title: "Show Table Settings" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openConfig.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "flaticon-cogwheel-2" })]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
                _c("b-pagination", {
                  key: `key_${_vm.tableKey}`,
                  staticClass: "ml-2 mt-1 mb-0 student-pagnation",
                  attrs: {
                    size: "md",
                    "hide-goto-end-buttons": "",
                    "total-rows": _vm.totalRows,
                    limit: _vm.deviceType == "mobile" ? 1 : 3,
                    "per-page": _vm.perPage,
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-input-icon kt-input-icon--right kt-subheader__search pl-3",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Filter students...",
                      },
                      domProps: { value: _vm.filter },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.filter = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-input-icon__icon kt-input-icon__icon--right",
                      },
                      [
                        _c("span", [
                          _c("i", { staticClass: "flaticon2-search-1" }),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c("span", { staticClass: "text-muted mr-2" }, [
                  _vm._v("View:"),
                ]),
                _c(
                  "a",
                  {
                    class: [
                      "btn kt-subheader__btn-secondary",
                      _vm.view == "table" ? "active" : "",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.openView("table")
                      },
                    },
                  },
                  [_vm._v(" Table ")]
                ),
                _c(
                  "a",
                  {
                    class: [
                      "btn kt-subheader__btn-secondary",
                      _vm.view == "list" ? "active" : "",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.openView("list")
                      },
                    },
                  },
                  [_vm._v(" List ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-student-dataview kt-container--fluid kt-grid__item kt-grid__item--fluid pl-2 ml-3 mr-4",
        },
        [
          _vm.totalRows == 0
            ? _c("div", { staticClass: "col" }, [_vm._m(0)])
            : [
                _vm.view == "table"
                  ? [
                      _c("div", { staticClass: "kt-portlet p-0 m-0" }, [
                        _c(
                          "div",
                          { staticClass: "kt-portlet__body p-0 m-0" },
                          [
                            _c("StudentDataViewHotTable", {
                              key: _vm.tableKey,
                              ref: "hotContainer",
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _c("StudentList"),
              ],
          _c("StudentDataViewModal", { ref: "table-config" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate fade show",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(" Loading data, please wait... "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }