<template>
<div class="row">
    <div
        v-for="student in students"
        :key="`student_${student.studentEnrollmentId}_${key}`"
        class="col-xl-3 col-lg-4 col-md-4 col-sm-6"
    >
        <StudentListItem :student="student" />
    </div>
</div>
</template>

<script lang="ts">
import StudentListItem from './StudentListItem.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'StudentList',
    components: {
        StudentListItem
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        filter() {
            return this.$store.state.database.studentDataView.filter;
        },
        students() {
            return this.$store.state.database.studentDataView.rows.map(function(row, idx) {
                if (idx === 0) return null;
                const [studentColumn] = row;
                return studentColumn.cellValue;
            }).filter(function(a) {
                return a;
            });
        },
    },
});
</script>

<style scoped>
</style>
