var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.students, function (student) {
      return _c(
        "div",
        {
          key: `student_${student.studentEnrollmentId}_${_vm.key}`,
          staticClass: "col-xl-3 col-lg-4 col-md-4 col-sm-6",
        },
        [_c("StudentListItem", { attrs: { student: student } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }